@tailwind base;
@tailwind components;
@tailwind utilities;

  @layer base {
    :root {
      --background: 0 0% 100%;
      --foreground: 222.2 84% 4.9%;

      --card: 0 0% 100%;
      --card-foreground: 222.2 84% 4.9%;

      --popover: 0 0% 100%;
      --popover-foreground: 222.2 84% 4.9%;

      --primary: #F3DFAC;
      --primary-foreground: #413B25;
      --primary-light: #EFE4C3;
      --primary-lighter: #F9F4E7;

      --secondary: #639C6E;
      --secondary-foreground: #ffffff;
      --secondary-dark: #456D4D;
      --secondary-light: 132 22% 65%;
      --secondary-lighter: #C1D8C5;
      --secondary-lighterest: #F0F6F1;

      --muted: 210 40% 96.1%;
      --muted-foreground: 215.4 16.3% 46.9%;

      --accent: #9C1923;
      --accent-foreground: #ffffff;
      --accent-light: #DC2533;
      --accent-lighter: #E76771;

      --destructive: #9C1923;
      --destructive-foreground: #ffffff;
      --destructive-light: #DC2533;
      --destructive-lighter: #E76771;

      --border: 214.3 31.8% 91.4%;
      --input: 214.3 31.8% 91.4%;
      --ring: 222.2 84% 4.9%;

      --radius: 0.5rem;
    }
  }

  @layer base {
    * {
      @apply border-border;
    }
    body {
      @apply bg-background text-foreground;
    }
  }